import React, { useEffect, useState, useRef } from 'react';
import './Home.scss'
import { Input, Button, List, Checkbox, Tag } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../api';
import md5 from 'md5'

const Home = () => {

    const [selectProvinceIndex, setSelectProvinceIndex] = useState(0)
    const [selectProfessionIndex, setSelectProfessionIndex] = useState(0)
    const [selectTimeIndex, setSelectTimeIndex] = useState(0)
    const [cusname, setCusname] = useState('')
    const [content, setContent] = useState('')
    const [page, setPage] = useState(1)
    const [companyList, setCompanyList] = useState([])
    const [total, setTotal] = useState(0)
    const [refresh, setRefresh] = useState(new Date().getTime())

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getData()
    }, [page, refresh])

    const getData = () => {
        const min = 100000;
        const max = 999999;
        const noce = `${Math.floor(Math.random() * (max - min + 1)) + min}`;
        let area = '', create_date_type = ''
        if (selectProvinceIndex > 0) {
            area = provinceList[selectProvinceIndex].name
        }
        if (selectTimeIndex > 0) {
            create_date_type = selectTimeIndex + ''
        }
        const timestamp = JSON.stringify(Date.parse(new Date()));
        const auth = md5(noce + timestamp + '’1B9098AD68113FF6');
        const params = {
            cusname,
            content,
            area,
            create_date_type,
            auth,
            timestamp,
            noce,
            page
        }
        console.log(params)
        Api.searchCusList(params).then(res => {
            setCompanyList(res.data.list)
            setTotal(res.data.total)
        }).catch(error => { console.log('获取数据失败', error) })
    }

    const onSelectProvince = (index) => {
        setSelectProvinceIndex(index)
    }

    const onSelectProfession = (index) => {
        setSelectProfessionIndex(index)
    }

    const onSelectTime = (index) => {
        setSelectTimeIndex(index)
    }

    const onPageChange = (page) => {
        setPage(page)
    }

    const onResetSearch = () => {
        setCusname('')
        setContent('')
        setSelectTimeIndex(0)
        setSelectProvinceIndex(0)
        setRefresh(new Date().getTime())
        setPage(1)
    }

    const onSearchData = () => {
        setRefresh(new Date().getTime())
        setPage(1)
    }

    return (
        <div className='home'>
            <div className='header'>
                <div className='header-title'>筛选条件</div>
                <div className='item-row'>
                    <div className='search-item'>
                        <div className='item-label'>企业名称</div>
                        <Input className='input' type='text' autoComplete='off' value={cusname} placeholder="请输入关键字，如企业名称、统一社会信用代码" onChange={e => setCusname(e.target.value)} />
                    </div>
                    <div className='search-item'>
                        <div className='item-label'>经营范围</div>
                        <Input className='input' type='text' autoComplete='off' value={content} placeholder="多个关键词用空格隔开，如：市政  园林" onChange={e => setContent(e.target.value)} />
                    </div>
                </div>
                <div className='search-item-1'>
                    <div className='item-label'>省份地区</div>
                    <div className='option-box'>
                        {provinceList.map((item, index) => {
                            const type = selectProvinceIndex == index ? 'primary' : 'text'
                            return (
                                <Button className='option' type={type} ghost key={item.id} onClick={() => onSelectProvince(index)}>{item.name}</Button>
                            )
                        })}
                    </div>
                </div>
                {/* <div className='search-item-1'>
                    <div className='item-label'>行业分类</div>
                    <div className='option-box'>
                        {professionList.map((item, index) => {
                            const type = selectProfessionIndex == index ? 'primary' : 'text'
                            return (
                                <Button className='option' type={type} ghost key={item} onClick={() => onSelectProfession(index)}>{item}</Button>
                            )
                        })}
                    </div>
                </div> */}
                <div className='search-item-1'>
                    <div className='item-label'>注册时长</div>
                    <div className='option-box'>
                        {timeList.map((item, index) => {
                            const type = selectTimeIndex == index ? 'primary' : 'text'
                            return (
                                <Button className='option' type={type} ghost key={item} onClick={() => onSelectTime(index)}>{item}</Button>
                            )
                        })}
                    </div>
                </div>
                <div className='search-box'>
                    <Button className='search-btn' type='primary' style={{ background: '#ff7e24' }} onClick={onSearchData}>查询</Button>
                    <Button className='search-btn' onClick={onResetSearch}>重置</Button>
                </div>
            </div>
            <div className='bottom-toolbar'>
                <div className='bottom-toolbar-left'>
                    为您找到<span className="toolbar-left-red">{total}</span>家相关企业
                </div>
                <div className="bottom-toolbar-right">
                    <Button className='add-crm' type='primary'>添加到CRM</Button>
                </div>
            </div>
            <List
                dataSource={companyList}
                pagination={{
                    pageSize: 15,
                    current: page,
                    hideOnSinglePage: true,
                    total,
                    showSizeChanger: false,
                    onChange: onPageChange,
                }}
                renderItem={(item) => (
                    <div className='company-box'>
                        <Checkbox />
                        <div className="info-list-left">
                            {/* <img src="https://qxb-logo-url.oss-cn-hangzhou.aliyuncs.com/OriginalUrl/2cae9ba958e78d719c43fd0f7e4fa514.jpg" /> */}
                        </div>
                        <div className="info-list-right">
                            <div className='card-title-box'>
                                <p className='card-title'>{item.cusname}</p>
                                <Button className='add-crm' type='primary'>添加到CRM</Button>
                            </div>
                            <div className='tag-box'>
                                <Tag bordered={false} color="green">{item.cunxu}</Tag>
                                {/* <Tag bordered={false} color="blue">高新企业</Tag>
                                <Tag bordered={false} color="red">严重行政处罚</Tag> */}
                            </div>
                            <div className="card-sub-title">
                                <span className="sub-title-grey">法定代表人：</span>
                                <span className="sub-title-black">{item.corp}</span>
                                <span className="sub-title-grey">注册资本：</span>
                                <span className="sub-title-black">{`${item.capital}万元`}</span>
                                <span className="sub-title-grey">成立日期：</span>
                                <span className="sub-title-black">{item.create_date}</span>
                                <span className="sub-title-grey"> 统一社会信用代码：</span>
                                <span className="sub-title-black">{item.xydm}</span>
                            </div>
                            <div className="card-sub-title" style={{ paddingTop: '10px' }}>
                                <span className="sub-title-grey">参保人员：</span>
                                <span className="sub-title-blue">{item.field_custom_13}</span>
                                <span className="sub-title-grey">地址：</span>
                                <span className="sub-title-blue">{item.address}</span>
                                {/* <span className="sub-title-grey">企业资质：</span>
                                <span className="sub-title-blue">23</span>
                                <span className="sub-title-grey">中标业绩：</span>
                                <span className="sub-title-blue">4779</span>
                                <span className="sub-title-grey">四库业绩：</span>
                                <span className="sub-title-blue">3585</span>
                                <span className="sub-title-grey">注册人员：</span>
                                <span className="sub-title-blue">8357</span>
                                <span className="sub-title-grey">三类人员：</span>
                                <span className="sub-title-blue">17763</span>
                                <span className="sub-title-grey">招标公告：</span>
                                <span className="sub-title-blue">1799</span>
                                <span className="sub-title-grey">客户：</span>
                                <span className="sub-title-blue">3038</span>
                                <span className="sub-title-grey">供应商：</span>
                                <span className="sub-title-blue">1252</span> */}
                            </div>
                        </div>
                    </div>
                )}
            />
        </div>
    )
}

export default Home

const provinceList = [
    { "id": "0", "name": "全部" },
    { "id": "2", "name": "北京" },
    { "id": "4", "name": "安徽" },
    { "id": "22", "name": "福建" },
    { "id": "32", "name": "甘肃" },
    { "id": "47", "name": "广东" },
    { "id": "69", "name": "广西" },
    { "id": "84", "name": "贵州" },
    { "id": "94", "name": "海南" },
    { "id": "113", "name": "河北" },
    { "id": "125", "name": "河南" },
    { "id": "144", "name": "黑龙江" },
    { "id": "158", "name": "湖北" },
    { "id": "176", "name": "湖南" },
    { "id": "191", "name": "吉林" },
    { "id": "201", "name": "江苏" },
    { "id": "215", "name": "江西" },
    { "id": "227", "name": "辽宁" },
    { "id": "242", "name": "内蒙古" },
    { "id": "255", "name": "宁夏" },
    { "id": "261", "name": "青海" },
    { "id": "270", "name": "山东" },
    { "id": "288", "name": "山西" },
    { "id": "300", "name": "陕西" },
    { "id": "311", "name": "上海" },
    { "id": "313", "name": "四川" },
    { "id": "335", "name": "天津" },
    { "id": "337", "name": "西藏" },
    { "id": "345", "name": "新疆" },
    { "id": "362", "name": "云南" },
    { "id": "379", "name": "浙江" },
    { "id": "391", "name": "重庆" },
    { "id": "393", "name": "香港" },
    { "id": "395", "name": "澳门" },
    { "id": "397", "name": "台湾" }
]
const professionList = ['全部', '农、林、牧、渔业', '采矿业', '制造业', '建筑业', '批发与零售业', '住宿和餐饮业']
const timeList = ['全部', '不足1年', '1-3年', '3-5年', '5-10年', '10-15年', '15年以上']
const data = [1, 1, 1, 1, 11, 1, 1];