const successCode = [200];

const baseUrl = process.env.NODE_ENV === "development" ? '/api/index.php/' : 'https://api.byteintel.cn/index.php/';

const post = (url, params) => {
    const goalUrl = baseUrl + url;
    const body = JSON.stringify(params);
    const headers = {
        'content-type': 'application/json;charset=UTF-8',
    };
    const options = { method: 'POST', body, headers };
    return fetch(goalUrl, options)
        .then(statusCheck)
        .then(parseJSON)
        .then(resultCheck);
}

function statusCheck(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    const error = new Error(response.statusText || '服务异常！');
    error.response = response;
    throw error;
}

function parseJSON(response) {
    return response.json();
}

function resultCheck(data) {
    if (successCode.includes(data.res)) return data;
    const error = new Error(data.msg || '服务异常！');
    error.response = data;
    throw error;
}

export {
    post,
}